<template>
	<v-col xl="6" md="6" sm="12" cols="12" class="d-flex justify-center align-center text-center">
		<v-row class="d-flex justify-center align-center text-center" style="position: relative">
			<div style="position: absolute; top: 40px">
				<strong class="ortho-text-green-flash">{{ sum(result.questions_id) * 10 }}</strong>
				<img class="lighteningIcons" src="@/assets/icons/lightening-green.svg" />
				<span class="ortho-text-green-flash">/50</span>
			</div>
			<v-progress-circular
				rotate="90"
				size="100"
				width="15"
				:value="(sum(result.questions_id) / result.questions_id.length) * 100"
				color="#34e5c8"
			>
			</v-progress-circular>
		</v-row>
	</v-col>
</template>

<script>
import moment from 'moment';

export default {
	name: 'Charts',
	props: {
		result: Object,
	},
	methods: {
		sum(questionsResults) {
			let total = 0;
			questionsResults.map((element) => {
				if (element.is_good_answer == true) {
					total++;
				}
			});
			if (total == 0) {
				return 0;
			} else {
				return total;
			}
		},
		randomNumberMemoryPercentage() {
			let day = moment().day();

			if (day % 2 == 0) {
				return 70;
			} else {
				return 90;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.v-progress-circular__underlay {
		stroke: rgba(242, 242, 242, 0.85);
	}
}
.myScoreContainer {
	text-align: right;
}
.averageScoreContainer {
	text-align: left;
}
@media only screen and (max-width: 900px) {
	.myScoreContainer {
		text-align: center;
	}
	.averageScoreContainer {
		text-align: center;
	}
}
</style>
