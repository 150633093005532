<template>
	<div class="pa-md-0 pa-3">
		<v-container class="feedback-title-container">
			<h4 class="ortho-text-gray">Nous aussi on veut s'améliorer ! L'exercice t'a plu ?</h4>
		</v-container>
		<v-form v-if="!submitFeedback" class="row mx-auto col-md-6 col-12 flex-column">
			<star-rating
				class="mx-auto"
				v-model="rating"
				:star-size="40"
				@rating-selected="showComments = true"
				:show-rating="false"
			>
			</star-rating>

			<div v-if="showComments" class="mt20 row flex-column justify-center">
				<v-textarea
					v-model="comments"
					name="comments"
					color="grey"
					auto-grow
					rows="1"
					label="Un commentaire ? (facultatif)"
				></v-textarea>
				<v-btn
					:disabled="disabledSubmit"
					:dark="!disabledSubmit"
					color="#15c39a"
					class="hvr-grow mx-auto mt-2"
					@click="validateFeedback"
				>
					Envoyer
				</v-btn>
			</div>
		</v-form>
		<div v-else class="row mx-auto col-md-6 col-12 flex-column">
			<v-alert outlined type="success" color="#15c39a">Votre commentaire a bien été envoyé, merci</v-alert>
		</div>
		<ModalForReviewApp
			:noFeedbacksExercices="noFeedbacksExercices"
			:totalScoreInPercent="totalScoreInPercent"
			:rating="rating"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import StarRating from 'vue-star-rating';
import ModalForReviewApp from '../../../other/ModalForReviewApp/ModalForReviewApp.vue';

export default {
	name: 'FeedbacksExercice',
	components: {
		StarRating,
		ModalForReviewApp,
	},
	computed: {
		...mapGetters('exercice', {
			noFeedbacksExercices: 'getNoFeedbacksExercices',
		}),
	},
	data: () => {
		return {
			disabledSubmit: false,
			showComments: false,
			submitFeedback: false,
			rating: 0,
			comments: '',
		};
	},
	props: {
		totalScoreInPercent: Number,
	},
	methods: {
		validateFeedback() {
			this.disabledSubmit = true;
			this.$http
				.post('/api/exercice/feedback', {
					idFormation: this.$route.params.idFormation,
					idExercice: this.$route.params.idExercice,
					rating: this.rating,
					comments: this.comments,
				})
				.then((res) => {
					if (res.status == 200) {
						this.submitFeedback = true;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style scoped>
.feedback-title-container {
	text-align: center;
}
</style>
