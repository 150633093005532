<template>
	<div>
		<v-col offset-md="3" xl="6" md="6" sm="12" cols="12" v-if="totalScoreInPercent">
			<GoToReportBlock
				v-if="totalScoreInPercent >= 80"
				:totalScoreInPercent="totalScoreInPercent"
				:formationProgress="formationProgress"
				:categoryId="allExerciceFromFormationCategory._id"
			/>
			<ScoreFinal v-else :totalScoreInPercent="totalScoreInPercent" />
			<FeedbacksExercice :totalScoreInPercent="totalScoreInPercent" />
			<ButtonsFinal class="mt-8" :totalScoreInPercent="totalScoreInPercent" />
			<Strike :strike="strike" class="mt-8" />
			<ChartsResultExercice :totalScoreInPercent="totalScoreInPercent" class="mt-8" />
			<ScoreByStories :stories="stories" :resultToDisplay="resultToDisplay" class="mt-8" />
			<a v-if="isUserOnAndroidApp" id="ReviewAndroidAppLink" class="invisible" href="orthographiq-app://review"
				>Rate app</a
			>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ScoreFinal from './default/ScoreFinal';
import FeedbacksExercice from './default/FeedbacksExercice';
import ButtonsFinal from './default/ButtonsFinal';
import Strike from './default/Strike';
import ChartsResultExercice from './default/ChartsResultExercice';
import ScoreByStories from './default/ScoreByStories';
import GoToReportBlock from './default/reports/GoToReportBlock';
import { detectAndroidUserByReferrer } from '../../../utils/helper';

export default {
	name: 'Default',
	components: {
		ScoreFinal,
		ButtonsFinal,
		FeedbacksExercice,
		Strike,
		ChartsResultExercice,
		ScoreByStories,
		GoToReportBlock,
	},
	data() {
		return {
			resultToDisplay: [],
			isUserOnAndroidApp: detectAndroidUserByReferrer(),
		};
	},
	computed: {
		...mapGetters('exercice', {
			resultExercice: 'resultExercice',
			scoreExercice: 'scoreExercice',
			resultStory: 'resultStory',
			allExerciceFromFormationCategory: 'allExerciceFromFormationCategory',
			exercice: 'exerciceInfos',
		}),
		...mapGetters('profile', {
			strike: 'strike',
			profileInfos: 'profileInfos',
			exercicesInfos: 'exercicesInfos',
			formationInfos: 'formationInfos',
			formationProgress: 'formationProgress',
		}),
		...mapGetters('formation', {
			formationData: 'formationData',
		}),
		totalScoreInPercent() {
			let total = 0;
			let sumQuestion = 0;
			let scoreRoute = null;
			if (this.$router.currentRoute.name == 'Story') {
				scoreRoute = this.resultStory;
			} else {
				scoreRoute = this.resultExercice;
			}
			scoreRoute.map((element) => {
				element.questions_id.map((question) => {
					if (question.is_good_answer == true) {
						total++;
					}
					sumQuestion++;
				});
			});
			return Math.ceil((total / sumQuestion) * 100);
		},
	},
	props: {
		stories: Array,
	},
	async mounted() {
		if (this.shouldDisplayReviewAndroidApp() === true) {
			this.openAndroidInAppReview();
		}
		window.scrollTo(0, 0);
		if (this.$router.currentRoute.name == 'Exercice') {
			await this.postScoreExercice({
				score: this.resultExercice,
				idFormation: this.$route.params.idFormation,
				idExercice: this.$route.params.idExercice,
				scoreGlobal: this.scoreExercice,
			});
			this.resultToDisplay = this.resultExercice;
			await this.getStrike({ idFormation: this.$route.params.idFormation });
		} else {
			await this.postScoreStory({
				idExercice: this.$route.params.idExercice,
				idFormation: this.$route.params.idFormation,
				scoreStory: this.resultExercice,
			});
			this.resultToDisplay = this.resultStory;
			await this.getStrike({ idFormation: this.$route.params.idFormation });
		}
		if (this.totalScoreInPercent >= 80) {
			this.$confetti.start({
				particlesPerFrame: 0.04,
			});
		}
		await this.getFormationData({
			idFormation: this.$route.params.idFormation,
		});
	},
	methods: {
		...mapActions('exercice', ['postScoreExercice']),
		...mapActions('exercice', ['postScoreStory']),
		...mapActions('profile', ['getStrike']),
		...mapActions('formation', ['getFormationData']),
		shouldDisplayReviewAndroidApp() {
			const uniqueExerciseEligibleToreview = [2, 3, 4, 5, 10, 50, 75, 78, 79];

			if (this.profileInfos == null || this.exercicesInfos == null) return false;
			if (this.isUserOnAndroidApp === false) return false;
			const profileActive = this.profileInfos.is_natif;
			const numUniqueExerciseDone = this.exercicesInfos.length;
			if (
				profileActive === true &&
				uniqueExerciseEligibleToreview.includes(numUniqueExerciseDone) === true &&
				this.totalScoreInPercent >= 80
			)
				return true;
			return false;
		},
		openAndroidInAppReview() {
			const reviewLinkElement = document.querySelector('#ReviewAndroidAppLink');
			if (reviewLinkElement != null) reviewLinkElement.click();
		},
	},
};
</script>

<style lang="scss" scoped>
.border-result {
	padding: 2rem;
}
</style>
